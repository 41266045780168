import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function usepincodeList() {

    const toast = useToast();


    const pageLength = ref(10);

    const columns = [
        {
            label: "Name",
            field: "name",
        },
        {
            label: "Pincode",
            field: "pincode",
        },
        {
            label: "Price",
            field: "price",
        },

        {
            label: "Status",
            field: "status",
        },
        {
            label: "Action",
            field: "action",
        },
    ];

    var searchTerm = ref('');


    const pincode = ref([]);

    const statusVariant = computed(() => {
        const statusColor = {
            1: "light-success",
            0: "light-danger",
        };
        return (status) => statusColor[status];
    })

    const fetchpincode = async () => {
        store
            .dispatch('master-pincode/fetchpincode', {})
            .then(response => {

                if (response.data.result == 1) {

                    pincode.value = response.data.data;

                } else {

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: `${response.data.msg}`,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })


                }

            })
            .catch((error) => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error: Fetching pincode',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }


  
    const refetchpincode = () => {
        fetchpincode();
    }


    const addpincode = async (formData) => {
        return store
            .dispatch('master-pincode/addpincode', formData)
            .then(response => {

                if (response.data.result == 1) {

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: `${response.data.msg}`,
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })
                    return true;

                } else {

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: `${response.data.msg}`,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })


                }



            })
            .catch((error) => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error: Adding pincode',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
                return false;
            })
    }


    const updatepincode = async (formData) => {
        return store
            .dispatch('master-pincode/updatepincode', formData)
            .then(response => {

                if (response.data.result == 1) {

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: `${response.data.msg}`,
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })
                    return true;

                } else {

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: `${response.data.msg}`,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })


                }



            })
            .catch((error) => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error: Adding pincode',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
                return false;
            })
    }

    const deletepincode = async (formData) => {
        return store
            .dispatch('master-pincode/deletepincode', formData)
            .then(response => {

                if (response.data.result == 1) {

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: `${response.data.msg}`,
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })
                    return true;

                } else {

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: `${response.data.msg}`,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })


                }



            })
            .catch((error) => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error: Adding pincode',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
                return false;
            })
    }

    const addUser = async (formData) => {
        return store
            .dispatch('app-user/addUser', formData)
            .then(response => {
                console.log(response);
                if (response.data.success && response.data.success == true) {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'User added successfuly',
                            icon: 'CheckIcon',
                            variant: 'Success',
                        },
                    })
                    return true;
                } else {
                    return { error: response.data.status };
                }
            })
            .catch((error) => {
                console.log(error);
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error while adding user',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                });
                return false;
            })
    }



    return {
        fetchpincode,
        addpincode,
        refetchpincode,
        updatepincode,
        deletepincode,
        columns,
        pageLength,
        searchTerm,
        pincode,
        statusVariant,


    }
}